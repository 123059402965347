import React from 'react'
import './NewArrivalandGiftNecklaces.css'
import GiftNecklaces from '../GiftNecklaces/GiftNecklaces'
import NewArrial from '../NewArrival/NewArrial'

const NewArrivalandGiftNecklaces = () => {
  return (
    <div className='new_arrival_gift_necklaces'>
      <GiftNecklaces/>
      <NewArrial/>
    </div>
  )
}

export default NewArrivalandGiftNecklaces
