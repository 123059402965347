import React from 'react'
import './UnderShop.css'
import OffLipFaves from '../OffLipFaves/OffLipFaves'
import BraceletandKeychains from '../BraceletandKeychains/BraceletandKeychains'

const UnderShop = () => {
  return (
    <div className="under_shop">
      <OffLipFaves/>
      <BraceletandKeychains/>
    </div>
  )
}

export default UnderShop
